export default {
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'change',
          },
        ],
        surname: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'change',
          },
          {
            min: 18,
            max: 18,
            message: 'Некорректный номер',
            trigger: 'blur',
          },
        ],
        login: [
          { required: true, message: 'Логин обязательный', trigger: 'blur' },
          {
            min: 4,
            message: 'Логин слишком короткий',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'blur',
          },
          {
            min: 4,
            message: 'Пароль слишком короткий',
            trigger: 'blur',
          },
          {
            max: 18,
            message: 'Максимальная длина - 18 символов',
            trigger: 'blur',
          },
        ],
        passwordCheck: [
          {
            validator: (rule, value, callback) => {
              if (value !== this.formData.password) {
                callback(new Error('Пароли не совпадают'))
              } else if (!value.length) {
                callback(new Error('Поле обязательно для заполнения'))
              } else if (value.length < 4) {
                callback(new Error('Пароль слишком короткий'))
              } else if (value.length > 18) {
                callback(new Error('Максимальная длина - 18 символов'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        passRecCheck: [
          {
            validator: (rule, value, callback) => {
              if (!value.length) {
                callback(new Error('Поле обязательно для заполнения'))
              } else if (value.length < 4) {
                callback(new Error('Пароль слишком короткий'))
              } else if (value !== this.newPass_form.password) {
                callback(new Error('Пароли не совпадают'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        confidentiality: [
          {
            validator: (rule, value, callback) => {
              if (!this.formData.privacyPolicy) {
                callback(
                  new Error('Наш юрист будет ругаться, если вы не примете :('),
                )
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'blur',
          },
        ],
      },
    }
  },
}
